<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  buttonType: {
    // "submit" | "reset" | "button"
    type: String as PropType<"submit" | "reset" | "button">,
    default: "button",
  },
  type: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    default: "md",
  },
  to: {
    type: [String, Object],
    default: undefined,
  },
  href: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// state:styles
const defaultStyle = `
  cursor-pointer shadow-sm
  border transition-color duration-300
  focus:outline-none focus:ring-1 focus:ring-offset-1 focus:dark:ring-offset-gray-50 focus:dark:ring-gray-400 focus:ring-gray-600/[0.6] focus:ring-offset-gray-800/[0.6]
  flex items-center justify-center font-semibold
`;
const styles = reactive<{
  [key: string]: string;
}>({
  none: "",
  primary:
    "text-gray-50 hover:text-gray-100 bg-primary-500 hover:bg-primary-600 border-primary-500",
  primaryOutline:
    "text-gray-900 dark:text-white hover:text-primary-400 bg-transparent dark:border-white border-gray-900 hover:border-primary-600 dark:hover:border-primary-400",
  secondary:
    "text-slate-800 bg-gray-200 border-gray-200 hover:bg-gray-300 dark:text-white dark:border-slate-800 dark:bg-slate-800 dark:hover:bg-slate-700",
  opposite:
    "text-white bg-gray-800 hover:bg-white hover:text-gray-800 hover:border-gray-900 dark:text-gray-800 dark:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-100 dark:border-white",
});
const sizes = reactive<{
  [key: string]: string;
}>({
  xl: "h-15 px-10 text-xl rounded-lg py-4",
  lg: "h-13 px-8 text-lg rounded-lg",
  md: "h-10 px-6 text-base rounded-md",
  sm: "h-9 px-4 text-sm rounded",
  xs: "h-6 px-3 text-xs rounded",
});

// state
const selectedStyle = computed(() => styles[props.type] || styles.primary);
const selectedSize = computed(() => sizes[props.size] || sizes.lg);
const disabledStyle = computed(() =>
  props.disabled === true || props.loading === true
    ? "!bg-gray-300 dark:bg-gray-100 text-gray-700 !border-gray-300 !dark:border-gray-100 pointer-events-none cursor-not-allowed"
    : "",
);

// methods
const onClick = (event: MouseEvent) => {
  const router = useRouter();
  if (props.to) {
    router.push(props.to);
  }
  if (!props.href) {
    event.preventDefault();
  }
};
</script>

<template>
  <NuxtLink
    v-if="to"
    tag="a"
    :to="to"
    :class="`${defaultStyle} ${selectedStyle} ${selectedSize} ${disabledStyle}`"
    @click="onClick"
  >
    <svg
      v-if="loading"
      class="animate-spin h-5 w-5 text-black"
      :class="{
        'ltr:-ml-1 ltr:mr-3 rtl:ml-3 rtl:-mr-1': text !== '',
        'mx-auto w-full': text === '',
      }"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot>{{ text }}</slot>
  </NuxtLink>
  <button
    v-else-if="!to && !href"
    :type="buttonType"
    :class="`${defaultStyle} ${selectedStyle} ${selectedSize} ${disabledStyle}`"
    @click="onClick"
  >
    <svg
      v-if="loading"
      class="animate-spin h-5 w-5 text-black"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      :class="{
        'ltr:-ml-1 ltr:mr-3 rtl:ml-3 rtl:-mr-1': text !== '',
      }"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot>{{ text }}</slot>
  </button>
  <a
    v-else
    :class="`${defaultStyle} ${selectedStyle} ${selectedSize} ${disabledStyle}`"
    :href="href"
    @click="onClick"
  >
    <svg
      v-if="loading"
      class="animate-spin h-5 w-5 text-black"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      :class="{
        'ltr:-ml-1 ltr:mr-3 rtl:ml-3 rtl:-mr-1': text !== '',
      }"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot>{{ text }}</slot>
  </a>
</template>
